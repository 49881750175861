export default {
    state: {
        types: [],
    },
    getters: {
        USER_WORK_SCHEDULE_EXCEPTION_TYPES: state => {
            return state.types;
        }
    },
    mutations: {
        SET_USER_WORK_SCHEDULE_EXCEPTION_TYPES: (state, payload) => {
            state.types = payload;
        },
    },
    actions: {
        GET_USER_WORK_SCHEDULE_EXCEPTION_TYPES: ({commit}) => {
            return new Promise((resolve) => {
                const data = [
                    { value: "0", text: "zmiana godzin pracy", color: "blue" },
                    { value: "1", text: "urlop", color: "orange", icon: "do_not_disturb_on" },
                    { value: "2", text: "zwolnienie lekarskie", color: "red", icon: "local_hospital" },
                    { value: "3", text: "opieka", color: "green", icon: "child_care" },
                    { value: "4", text: "uzgodniona nieobecność", color: "grey",  icon: "do_not_disturb_on"  },
                ];
                commit("SET_USER_WORK_SCHEDULE_EXCEPTION_TYPES", data);
                resolve(true);  
            });
        },
    }
}