<template>

  <v-card>
    <v-card-text class="pa-0 ma-0">
      <CoworkersStatusCalendarVue ref="coworkersStatusCalendar" />
    </v-card-text>
  </v-card>

</template>

<script>
import moment from "moment";

import CoworkersStatusCalendarVue from "./CoworkersStatusCalendar.vue";
export default {
  name: "CoworkersStatusDialog",
  data: () => ({
    loading: false,
  }),
  components: { CoworkersStatusCalendarVue },

  computed: {
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.coworkersStatusCalendar.getData();
    });
  },
  created() {
    moment.locale("pl");
  },
  methods: {},
  watch: {},
};
</script>


<style>
</style>