<template>
  <div v-if="getTypeById(exception.type)">
    <v-tooltip
      bottom
      :color="getTypeById(exception.type).color +' darken-2'"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-alert
          :color="getTypeById(exception.type).color +' darken-1'"
          dark
          dense
          class="text-center ma-0 pa-0"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon v-if="getTypeById(exception.type).icon">{{ getTypeById(exception.type).icon}}</v-icon>
          <div
            v-if="!getTypeById(exception.type).icon"
            class="text-truncate text-caption"
            style="max-width:80px;margin:3px auto;"
          >{{exception.description}}</div>
        </v-alert>
      </template>
      <p class="font-weight-bold text-center">{{ getTypeById(exception.type).text }}</p>
      <p class="mb-0 text-center">{{exception.description}}</p>
    </v-tooltip>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CalendarExceptionCell",
  props: ["exception"],
  data: () => ({
    loading: false,
  }),
  components: {},
  computed: {
    ...mapGetters(["USER_WORK_SCHEDULE_EXCEPTION_TYPES"]),
  },
  mounted() {
    this.loading = true;
    this.$store.dispatch("GET_USER_WORK_SCHEDULE_EXCEPTION_TYPES").then(() => {
      this.loading = false;
    });
  },
  methods: {
    getTypeById(id) {
      const element = this.USER_WORK_SCHEDULE_EXCEPTION_TYPES.find(
        (type) => type.value == id
      );
      return element;
    }
  },
  watch: {},
};
</script>


<style>
</style>