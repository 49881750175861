<template>
  <div>

    <v-btn
      icon
      dark
      @click="openDialog"
      @click.right="openWindow"
    >
      <v-icon>groups</v-icon>
    </v-btn>
    <CoworkersStatusDialog ref="coworkersStatusDialog" />
  </div>
</template>

<script>

import CoworkersStatusDialog from "./UserCoworkers/CoworkersStatusDialog.vue";


export default {
  name: "UserQa",
  components: { CoworkersStatusDialog },
  data: () => ({

  }),
  computed: {
  },
  methods: {
    openDialog(){
      this.$refs.coworkersStatusDialog.openDialog();
    },
    openWindow(){
      let routeData = this.$router.resolve({
        name: "coworkers",
      });
      window.open(routeData.href, "_blank");
    }
  },
  mounted() {},
  watch: {},
};
</script>
