<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    transition="dialog-top-transition"
  >

    <v-card>
      <v-card-title>

      </v-card-title>
      <v-card-text>     
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="dates"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-combobox
              v-model="workingScheduleException.dates"
              multiple
              chips
              small-chips
              label="dni"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-combobox>
          </template>
          <v-date-picker
            v-model="workingScheduleException.dates"
            multiple
            no-title
            scrollable
            locale="pl"
            :first-day-of-week="1"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="menu = false"
            >
              Anuluj
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menu.save(workingScheduleException.dates)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>

        <v-select
          :items="USER_WORK_SCHEDULE_EXCEPTION_TYPES"
          label="rodzaj"
          prepend-icon="directions_walk"
          v-model="workingScheduleException.type"
          clearable
        ></v-select>
        <v-text-field
          v-model="workingScheduleException.description"
          label="opis"
          prepend-icon="description"
        ></v-text-field>
        <v-autocomplete
            v-if="$acl.check('admin')" 
            v-model="workingScheduleException.user"
            :items="OPERATORS"
            item-text="name"
            return-object
            label="inny użytkownik"
            dense
            clearable
            :menu-props="{ maxHeight: 520, offsetOverflow: true }"
          >
          </v-autocomplete>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="dialog = false"
        >
          Anuluj
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="create()"
        >
          Dodaj
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>

</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "WorkingScheduleExceptionCreateDialog.vue",
  data: () => ({
    dialog: false,
    loading: false,
    workingScheduleException: {},
    dates: [],
    menu: false,
  }),
  components: {},
  computed: {
    ...mapGetters(["USER_WORK_SCHEDULE_EXCEPTION_TYPES"]),
    ...mapGetters(["OPERATORS"]),
  },
  mounted() {
    this.$store.dispatch("GET_OPERATORS");
  },
  methods: {
    openDialog() {
      this.dialog = true;
      this.workingScheduleException = JSON.parse(JSON.stringify({}));
    },
    closeDialog() {
      this.dialog = false;
    },
    create() {
      if (
        !this.workingScheduleException.dates ||
        !this.workingScheduleException.type ||
        !this.workingScheduleException.description
      ) {
        return false;
      }
      this.loading = true;
      this.$store
        .dispatch("ADD_USER_WORK_SCHEDULE_EXCEPTION", {
          userWorkScheduleException: this.workingScheduleException,
        })
        .then(() => {
          this.loading = false;
          this.workingScheduleException = {};
          this.closeDialog();
          this.$emit("updated");
        });
    },
  },
  watch: {},
};
</script>


<style>
</style>