<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="70"
      ></v-progress-circular>
    </v-overlay>
    <v-card v-if="item">
      <v-card-subtitle class="pt-4">
        <div class="text-caption">
          <span class="float-right">AKTUALIZACJA: {{ item.updated_at }}</span>
        </div>
        <h1 class="text-header-1 py-5">
           {{ item.question }}
        </h1>

        <div
          class="qa-answer-section"
          v-html="item.answer"
        ></div>

      </v-card-subtitle>

    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "QaReadWindow",
  data: () => ({
    item: {},
    loading: false
  }),
  components: {},
  computed: {
    ...mapGetters(["QA"]),
  },
  mounted() {
    this.loading = true;
    this.$store.dispatch("GET_QA").then(() => {
      let id = this.$route.params.id;
      this.item = this.QA.find((qa) => qa.id == id);
      this.loading = false;
    });
  },
  methods: {},
  watch: {},
};
</script>


<style>
.qa-answer-section table {
  border-collapse: collapse;
  margin: 20px 0px;
}
.qa-answer-section table td {
  padding: 0.55rem;
  border: 1px solid #dee2e6;
}
.qa-answer-section img {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 1150px;
}
</style>