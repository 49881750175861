<template>
  <v-row>
    <v-col sm=3>
      <DateTimePicker
        label="kolejny kontakt"
        v-model="facility.next_contact_at"
        defaultHour='8:00'
        :key="facility.id"
        :clearOldDateOnClick="true"
      ></DateTimePicker>
    </v-col>

    <v-col sm=9>
      <v-tooltip
        bottom
        color="error"
        :disabled="facility.next_contact_at ? true : false"
      >
        <template v-slot:activator="{ on }">
          <div
            v-on="on"
          >
            <v-text-field
              v-model="facility.next_contact_purpose"
              label="cel kontaktu"
              :disabled="!facility.next_contact_at"
              clearable
              dense
            >

              <template
                v-if="facility.next_contact_event"
                v-slot:append-outer
              >
                <v-menu
                  v-model="nextContactPopover"
                  :close-on-content-click="false"
                  :nudge-width="650"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">

                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      more_time
                    </v-icon>

                  </template>
                  <NextContactList :facility="facility" />
                </v-menu>

              </template>
            </v-text-field>
          </div>
        </template>
        <span>wybierz date kontaktu aby wprowadzić cel</span>
      </v-tooltip>

    </v-col>
    <!--<v-col sm="4">
         <v-select
          v-model="facility.supervisor"
          :items="OPERATORS"
          item-text="name"
          return-object
          label="opiekun oferty"
          dense
        >
        </v-select>
        
      </v-col>-->

  </v-row>
</template>

<script>
import NextContactList from "./NextContact/NextContactList.vue";
import DateTimePicker from "../../shared/DateTimePicker.vue";
export default {
  name: "FacilityEditFormNextContact",
  props: ["facility"],
  components: { NextContactList, DateTimePicker },
  data() {
    return {
      hide: false,
      nextContactAtDatepicker: false,
      nextContactPopover: false,
    };
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>


<style>
</style>