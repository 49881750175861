<template>
  <v-app>
    <v-app-bar
      app
      color="blue"
      dense
      flat
    >
      <v-app-bar-nav-icon
        dark
        @click.stop="mainNavigation = !mainNavigation"
      ></v-app-bar-nav-icon>

      <FacilityEditSearchNavigation v-on:facility-change="facilityAutoSave" />

      <v-spacer></v-spacer>
      <UserCoworkers />
      <UserQa />
      <UserNotification />
      <v-btn
        v-if="showCalendarButton"
        icon
        dark
        @click="openUserCalendar"
      >
        <v-icon>event</v-icon>
      </v-btn>
      <v-menu
        bottom
        left
        :offset-y="true"
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            v-if="CURRENT_USER"
            class="white--text"
            v-bind="attrs"
            v-on="on"
          >
            <v-avatar>
              <v-icon dark> person </v-icon>
            </v-avatar>
            {{ CURRENT_USER.username }}
          </div>
        </template>

        <v-list>
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            @click="callAction(item.action)"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

    </v-app-bar>
    <v-main>
      <router-view ref="view"></router-view>
    </v-main>
    <v-navigation-drawer
      v-model="mainNavigation"
      fixed
      left
      temporary
      floating
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="blue--text text--accent-4"
        >
          <div
            v-for="(item, index) in mainNavigationItems"
            :key="index"
          >
            <v-list-item
              @click="goTo(item.action)"
              @click.prevent
              :href="getUrl(item.action)"
              v-if="$acl.check(item.rule)"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-dialog
      v-model="userEditDialog"
      max-width="1200"
    >
      <UserEditSettings v-on:user:updated="userEditDialog=false" />
    </v-dialog>

    <v-dialog
      v-model="userReportDialog"
      max-width="1200"
    >
      <UserReport @close="userReportDialog=false" />
    </v-dialog>

    <UserCalendar ref="userCalendar" />

  </v-app>
</template>

<script>
import { mapGetters } from "vuex";

import FacilityEditSearchNavigation from "./../components/Facility/FacilityEditSearchNavigation";
import UserEditSettings from "./../components/User/UserEditSettings";
import UserReport from "./../components/User/UserReport";
import UserCalendar from "./../components/User/UserCalendar";
import UserNotification from "./../components/User/UserNotification";
import UserQa from "./../components/User/UserQa";
import UserCoworkers from "./../components/User/UserCoworkers";

export default {
  name: "AppLayout",
  data: () => ({
    items: [
      { title: "ustawienia", icon: "settings", action: "userSettings" },
      { title: "raport", icon: "summarize", action: "userReport" },
      { title: "wyloguj", icon: "logout", action: "logout" },
    ],
    mainNavigationItems: [
      {
        title: "Obiekty",
        action: "appDefault",
        icon: "holiday_village",
        rule: "supervisor",
      },
      {
        title: "Oferty",
        action: "listingSearch",
        icon: "hotel",
        rule: "supervisor",
      },
      {
        title: "Faktury",
        action: "invoice",
        icon: "receipt",
        rule: "supervisor",
      },
      {
        title: "Dodaj obiekt",
        action: "facilityCreate",
        icon: "add",
        rule: "supervisor",
      },
      {
        title: "Zarządzaj",
        action: "admin",
        icon: "settings",
        rule: "admin",
      },
    ],
    mainNavigation: false,
    userEditDialog: false,
    userReportDialog: false,
    group: false,
    showCalendarButton: false
  }),
  components: {
    FacilityEditSearchNavigation,
    UserEditSettings,
    UserCalendar,
    UserReport,
    UserNotification,
    UserQa,
    UserCoworkers
  },
  computed: {
    ...mapGetters(["CURRENT_USER"]),
  },
  methods: {
    callAction(action) {
      this[action]();
    },
    goTo(routerName) {
      this.$router.push({ name: routerName });
    },
    getUrl(routerName) {
      let routeData = this.$router.resolve({
        name: routerName,
      });
      return routeData.href;
    },
    facilityAutoSave() {
      this.$refs.view.forceSave();
    },
    userSettings() {
      this.userEditDialog = true;
    },
    userReport() {
      this.userReportDialog = true;
    },
    openUserCalendar() {
      this.$refs.userCalendar.open();
    },
    logout() {
      this.$store.dispatch("LOGOUT").then(() => {
        this.$router.push({ name: "login" });
      });
    },
  },
  mounted() {
    this.$store.dispatch("GET_CURRENT_USER").then(() => {
      const user = this.CURRENT_USER;
      if (user && user.roles.includes("ROLE_ADMIN")) {
        this.$acl.change("admin");
      }
      if (user && user.roles.includes("ROLE_SUPERVISOR")) {
        this.$acl.change("supervisor");
      }
      if (user && user.roles.includes("ROLE_ACCOUNTANT")) {
        this.$acl.change("accountant");
      }
    });
  },
};
</script>

<style>
#app .v-input {
  font-size: 15px;
}

#app .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td {
  font-size: 0.8rem;
}

#app .v-tab {
  font-size: 0.8rem;
}
</style>
